<template>
  <div class="table-box"  ref="tableBox">
    <div class="table-title">
      <el-checkbox
        :indeterminate="equityCardIsIndeterminate1"
        v-model="equityCardCheckAll1"
        @change="handleCheckAllChange1"
        >{{ title }}</el-checkbox
      >
      <span style="color: #A5A7AB"
        >{{
          this.equityCardSelect1.length
            ? this.equityCardSelect1.length + "/"
            : ""
        }}{{ equityCard1.length }}</span
      >
    </div>
    <div class="table-search">
      <el-input
        :placeholder="text"
        prefix-icon="el-icon-search"
        v-model="searchInput"
        @input="searchChange"
      >
      </el-input>
    </div>
    <div class="table-main" ref="table" :style="{ height: height }" v-if="equityCard1.length">
      <div class="main-box" v-for="item in equityCard1" :key="item.cardId">
        <el-checkbox
          style="margin-top:3px"
          v-model="item.checked"
          @change="handleCheckChange1($event, item)"
        >
          <div class="checkbox-main">
            <span class="name">{{ item.cardName }}</span>
            <span class="price">￥{{ item.cardPrice }}</span>
            <el-popover placement="right-start" width="300" trigger="hover">
              <div style="min-height:100px">
                <div style="text-align:center;padding-bottom:10px;">
                  查看卡内容
                </div>
                <div
                  v-for="(item2, index) in item.memberBenefitsList"
                  :key="index"
                >
                  {{ index + 1 }}. {{ item2.benefitsName }}
                </div>
              </div>
              <el-button type="text" class="button" slot="reference"
                >卡内容
                <div class="el-icon-arrow-right"></div
              ></el-button>
            </el-popover>
          </div>
        </el-checkbox>
      </div>
    </div>
    <div class="table-hint" v-if="!equityCard1.length">暂无数据</div>
  </div>
</template>

<script>
export default {
  props: {
    list: {
      type: Array,
      default: () => [],
    },
    title: {
      type: String,
      default: "",
    },
    text: {
      type: String,
      default: "",
    },
    height: {
      type: String,
      default: "calc(100vh - 360px)",
    },
  },
  data() {
    return {
      equityCardCheckAll1: false,
      equityCardIsIndeterminate1: false,
      equityCard1: [...this.list],
      equityCardSelect1: [],
      searchInput: "",
    };
  },
  created() {},
  mounted() {
  },
  methods: {
    // 勾选可选充值列表触发全选
    handleCheckAllChange1(val) {
      this.equityCardIsIndeterminate1 = false;
      if (val) {
        this.equityCard1 = this.equityCard1?.map((item) => {
          item.checked = true;
          return item;
        });
        this.equityCardSelect1 = this.equityCard1?.map((item) => item.cardId);
      } else {
        this.equityCard1 = this.equityCard1?.map((item) => {
          item.checked = false;
          return item;
        });
        this.equityCardSelect1 = [];
      }
      this.$emit("selectAll", this.equityCardSelect1);
    },
    // 勾选可选充值列表触发单选
    handleCheckChange1(val, data) {
      if (val) {
        this.equityCard1 = this.equityCard1?.map((item) => {
          if (item.cardId == data.cardId) {
            item.checked = true;
          }
          return item;
        });
      } else {
        this.equityCard1 = this.equityCard1?.map((item) => {
          if (item.cardId == data.cardId) {
            item.checked = false;
          }
          return item;
        });
      }
      this.equityCardCheckAll1 = this.equityCard1.every((item) => item.checked);
      if (this.equityCardCheckAll1) {
        this.equityCardIsIndeterminate1 = false;
      } else {
        this.equityCardIsIndeterminate1 = true;
      }
      let bool = this.equityCard1.every((item) => item.checked == false);
      if (bool) {
        this.equityCardIsIndeterminate1 = false;
      }
      this.equityCardSelect1 = this.equityCard1?.filter((item) => item.checked);
      this.equityCardSelect1 = this.equityCardSelect1.map(
        (item) => item.cardId
      );
      this.$emit("select", this.equityCardSelect1);
    },
    searchChange(value) {
      if (value) {
        this.equityCardSelect1 = [];
        this.equityCardIsIndeterminate1 = false;
        this.equityCard1 = this.equityCard1.map((item) => {
          item.checked = false;
          return item;
        });
        this.equityCard1 = this.equityCard1.filter((item) => {
          if (item.cardName.includes(value)) {
            return item;
          }
        });
      } else {
        this.equityCard1 = this.list;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.table-box {
  width: calc(50vw - 170px);
  border: 1px solid rgba(234, 237, 244, 100);
  border-radius: 5px;
  
  .table-title {
    height: 50px;
    line-height: 50px;
    background-color: rgba(243, 246, 249, 100);
    padding: 0 20px;
    display: flex;
    justify-content: space-between;
    color: #a5a7ab;
  }
  .table-search {
    padding: 10px 30px 20px;
    ::v-deep .el-input__inner {
      border-radius: 22px;
    }
  }
  .table-main {
    padding: 0 20px;
    font-size: 14px;
    overflow: auto;
    .main-box {
      padding-bottom: 10px;
      cursor: pointer;
      ::v-deep .el-checkbox__label{
        display: inline-grid;
        white-space: pre-line;
        word-wrap: break-word;
        overflow: hidden;
      }
      .checkbox-main {
        display: flex;
        // align-items: center;
        width: calc(50vw - 250px);
        .name {
          flex: 4;
          overflow: hidden;
        }
        .price {
          flex: 3;
        }
        .button {
          color: #898989;
          padding: 0;
          flex: 1;
        }
      }
    }
  }
  .table-hint {
    display: flex;
    justify-content: center;
    color: #898989;
  }
}
</style>

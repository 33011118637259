<template>
    <div class="card-set content-index">
        <div class="top-radio">
            <div style="position:absolute">
                <el-page-header
                        style="padding:10px"
                        @back="$router.go(-1)"
                        content="卡配置"
                ></el-page-header>
            </div>
            <el-radio-group v-model="activeName" @change="handleRadioChange">
                <el-radio-button label="1">权益卡</el-radio-button>
                <el-radio-button label="2">充值卡</el-radio-button>
            </el-radio-group>
        </div>
        <div v-if="activeName == 1">
            <div class="table-content">
                <div
                        v-loading="loading"
                        :style="
            loading
              ? 'width: calc(50vw - 170px);height:calc(100vh - 180px)'
              : ''
          "
                >
                    <SelectTable
                            v-if="!loading"
                            :list="equityCard1"
                            @selectAll="handleCheckAllChange1"
                            @select="handleCheckChange1"
                            :title="'可选充值卡列表'"
                            :text="'请输入权益卡名称'"
                            :height="'calc(100vh - 360px)'"
                    />
                </div>
                <div class="content-button">
                    <el-button
                            type="primary"
                            @click="clickLeft(1)"
                            :disabled="equityCardSelect2.length == 0"
                            icon="el-icon-arrow-left"
                    ></el-button>
                    <el-button
                            type="primary"
                            @click="clickRight(1)"
                            :disabled="equityCardSelect1.length == 0"
                            icon="el-icon-arrow-right"
                    ></el-button>
                </div>
                <div
                        v-loading="loading"
                        :style="
            loading
              ? 'width: calc(50vw - 170px);height:calc(100vh - 180px)'
              : ''
          "
                >
                    <SelectTable
                            v-if="!loading"
                            :list="equityCard2"
                            @selectAll="handleCheckAllChange2"
                            @select="handleCheckChange2"
                            :title="'已选充值卡列表'"
                            :text="'请输入权益卡名称'"
                            :height="'calc(100vh - 360px)'"
                    />
                </div>
            </div>
        </div>
        <div v-else>
            <div class="content-box">
                <el-tabs v-model="cardActivityType" @tab-click="handleClick1">
                    <el-tab-pane
                            v-for="(item, index) in tabsList"
                            :key="index"
                            :label="item.name"
                            :name="item.value"
                    ></el-tab-pane>
                </el-tabs>
                <el-tabs
                        v-model="cardActivityType"
                        @tab-click="handleClick1"
                        style="padding-right:5px"
                >
                    <el-tab-pane
                            v-for="(item, index) in tabsList"
                            :key="index"
                            :label="item.name"
                            :name="item.value"
                    ></el-tab-pane>
                </el-tabs>
            </div>
            <div class="table-content">
                <div
                        v-loading="loading"
                        :style="
            loading
              ? 'width: calc(50vw - 170px);height:calc(100vh - 180px)'
              : ''
          "
                >
                    <SelectTable
                            v-if="!loading"
                            :list="refillCard1"
                            @selectAll="handleCheckAllChange3"
                            @select="handleCheckChange3"
                            :title="'可选充值卡列表'"
                            :text="'请输入充值名称'"
                    />
                </div>
                <div class="content-button">
                    <el-button
                            type="primary"
                            @click="clickLeft(2)"
                            :disabled="refillCardSelect2.length == 0"
                            icon="el-icon-arrow-left"
                    ></el-button>
                    <el-button
                            type="primary"
                            :disabled="refillCardSelect1.length == 0"
                            @click="clickRight(2)"
                            icon="el-icon-arrow-right"
                    ></el-button>
                </div>
                <div
                        v-loading="loading"
                        :style="
            loading
              ? 'width: calc(50vw - 170px);height:calc(100vh - 180px)'
              : ''
          "
                >
                    <SelectTable
                            v-if="!loading"
                            :list="refillCard2"
                            @selectAll="handleCheckAllChange4"
                            @select="handleCheckChange4"
                            :title="'已选充值卡列表'"
                            :text="'请输入充值名称'"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {
    getMembercardList,
    getRefillCardList,
    updateStroeCard,
} from "@/api/member/card";
import SelectTable from "./components/selectTable";

export default {
    components: {
        SelectTable,
    },
    data() {
        return {
            tabsList: [
                {
                    name: "无优惠",
                    value: "1",
                },
                {
                    name: "送权益卡(不退)",
                    value: "2",
                },
                {
                    name: "送权益卡(可退)",
                    value: "3",
                },
                {
                    name: "送优惠券",
                    value: "4",
                },
                {
                    name: "送日化用品",
                    value: "5",
                },
            ],
            loading: false,
            activeName: "1",
            cardActivityType: "1",
            equityCard1: [],
            equityCardSelect1: [],
            equityCard2: [],
            equityCardSelect2: [],
            refillCard1: [],
            refillCardSelect1: [],
            refillCard2: [],
            refillCardSelect2: [],
            cardIdList: [],
        };
    },
    created() {
        this.getEquityCard();
    },
    methods: {
        // 获取权益卡列表
        getEquityCard() {
            this.loading = true;
            getMembercardList()
                .then(({data}) => {
                    if (data.code == 0) {
                        this.equityCard1 = data.data.memberCarVoList?.map((item) => {
                            item.checked = false;
                            return item;
                        });
                        this.equityCard2 = data.data.memberChooseCarVoList?.map((item) => {
                            item.checked = false;
                            return item;
                        });
                        this.loading = false;
                    } else {
                        this.loading = false;
                    }
                })
                .catch(() => {
                    this.loading = false;
                });
        },
        // 获取充值列表
        getRefillCard() {
            this.loading = true;
            getRefillCardList({cardActivityType: this.cardActivityType})
                .then(({data}) => {
                    if (data.code == 0) {
                        this.refillCard1 = data.data.memberCarVoList?.map((item) => {
                            item.checked = false;
                            return item;
                        });
                        this.refillCard2 = data.data.memberChooseCarVoList?.map((item) => {
                            item.checked = false;
                            return item;
                        });
                        this.loading = false;
                    } else {
                        this.loading = false;
                    }
                })
                .catch(() => {
                    this.loading = false;
                });
        },
        // 获取充值列表
        handleRadioChange(val) {
            if (val == 1) {
                this.getEquityCard();
            } else {
                this.getRefillCard();
            }
        },
        // tabs切换触发
        handleClick1() {
            this.getRefillCard();
        },
        clickLeft(val) {
            if (val == 1) {
                this.cardIdList = this.equityCardSelect2;
            } else {
                this.cardIdList = this.refillCardSelect2;
            }
            updateStroeCard({cardIdList: this.cardIdList, flag: 2})
                .then(({data}) => {
                    if (data.code == 0) {
                        this.$message.success("操作成功");
                        if (val == 1) {
                            this.getEquityCard();
                            this.equityCardSelect2 = [];
                        } else {
                            this.getRefillCard();
                            this.refillCardSelect2 = [];
                        }
                    } else {
                        this.$message.error("操作失败");
                    }
                })
                .catch(() => {
                });
        },
        clickRight(val) {
            if (val == 1) {
                this.cardIdList = this.equityCardSelect1;
            } else {
                this.cardIdList = this.refillCardSelect1;
            }
            updateStroeCard({cardIdList: this.cardIdList, flag: 1})
                .then(({data}) => {
                    if (data.code == 0) {
                        this.$message.success("操作成功");
                        if (val == 1) {
                            this.getEquityCard();
                            this.equityCardSelect1 = [];
                        } else {
                            this.getRefillCard();
                            this.refillCardSelect1 = [];
                        }
                    } else {
                        this.$message.error("操作失败");
                    }
                })
                .catch(() => {
                });
        },
        // 勾选可选办卡列表触发左边全选
        handleCheckAllChange1(val) {
            this.equityCardSelect1 = val;
        },
        // 勾选可选办卡列表触发左边单选
        handleCheckChange1(val) {
            this.equityCardSelect1 = val;
        },
        // 勾选可选办卡列表触发右边全选
        handleCheckAllChange2(val) {
            this.equityCardSelect2 = val;
        },
        // 勾选可选办卡列表触发右边单选
        handleCheckChange2(val) {
            this.equityCardSelect2 = val;
        },
        // 勾选可选充值列表触发左边全选
        handleCheckAllChange3(val) {
            this.refillCardSelect1 = val;
        },
        // 勾选可选充值列表触发左边单选
        handleCheckChange3(val) {
            this.refillCardSelect1 = val;
        },
        // 勾选可选充值列表触发右边全选
        handleCheckAllChange4(val) {
            this.refillCardSelect2 = val;
        },
        // 勾选可选充值列表触发右边单选
        handleCheckChange4(val) {
            this.refillCardSelect2 = val;
        },
    },
};
</script>

<style lang="scss" scoped>
.card-set {
  padding: 20px 30px;

  .top-radio {
    padding-bottom: 20px;
    text-align: center;
  }

  .content-box {
    display: flex;
    justify-content: space-between;

    .el-tabs {
      width: calc(50vw - 170px);

      ::v-deep .el-tabs__item {
        padding: 0 12px;
      }
    }
  }

  .table-content {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .table-box {
      width: calc(50vw - 170px);
      height: calc(100vh - 240px);
      border: 1px solid rgba(234, 237, 244, 100);
      border-radius: 5px;

      .table-title {
        height: 50px;
        line-height: 50px;
        background-color: rgba(243, 246, 249, 100);
        padding: 0 20px;
        display: flex;
        justify-content: space-between;
        color: #a5a7ab;
      }

      .table-search {
        padding: 10px 30px 20px;

        ::v-deep .el-input__inner {
          border-radius: 22px;
        }
      }

      .table-main {
        padding: 0 20px;
        font-size: 14px;

        .main-box {
          display: flex;
          padding-bottom: 10px;

          .name {
            padding-left: 10px;
            flex: 4;
          }

          .price {
            flex: 3;
          }

          .button {
            color: #898989;
            padding: 3px 0px;
            flex: 1;
          }
        }
      }

      .table-hint {
        display: flex;
        justify-content: center;
        color: #898989;
      }
    }
  }
}
</style>
